import React from "react";
import ReactPlayer from "react-player";
import whatisreVideo from "../assets/whatisre.mp4";
import whatisreThumb from "../assets/whatisrethumb.jpg";

const BenefitsSection = () => {
  return (
    <section className="container pb-5 my-md-3 my-lg-4 my-xxl-5">
      <div className="row align-items-center py-md-4 py-xl-5">
        <div className="col-md-6 mb-4 mb-md-0 d-flex justify-content-center">
          <div
            className="video-container"
            style={{
              borderRadius: "12px",
              overflow: "hidden",
              width: "100%",
              maxWidth: "540px",
              height: "540px",
              position: "relative",
            }}
          >
            <ReactPlayer
              url={whatisreVideo}
              light={whatisreThumb} // Use the thumbnail image here
              playing={true} // Video won't autoplay until clicked
              controls={true} // Show controls for the video
              width="100%"
              height="100%"
              style={{
                borderRadius: "12px",
                overflow: "hidden",
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="pt-1 pb-sm-4 ps-md-4 ps-xl-0">
            <h2 className="h1 pb-2 pb-sm-3 pb-xl-4 fw-semibold">
              Real Estate Investing Simplified
            </h2>
            <div className="d-flex pb-3 mb-2 mb-xl-3">
              <div className="text-body-secondary pt-1">01</div>
              <div className="ps-3">
                <h3 className="h5 fw-semibold">No Down payment</h3>
                <p className="mb-0">
                  Invest for as little as $100 with effortless and quick
                  investment
                </p>
              </div>
            </div>
            <div className="d-flex pb-3 mb-2 mb-xl-3">
              <div className="text-body-secondary pt-1">02</div>
              <div className="ps-3">
                <h3 className="h5 fw-semibold">Build wealth</h3>
                <p className="mb-0">
                  Earn rent from day one, build wealth with property
                  appreciation, and use vacation points for free getaways.
                </p>
              </div>
            </div>
            <div className="d-flex pb-3 mb-2 mb-xl-3">
              <div className="text-body-secondary pt-1">03</div>
              <div className="ps-3">
                <h3 className="h5 fw-semibold">Invest like a pro</h3>
                <p className="mb-0">
                  Access high-value assets without the need for significant
                  capital investments
                </p>
              </div>
            </div>
            <div className="d-flex mb-2 mb-xl-3">
              <div className="text-body-secondary pt-1">04</div>
              <div className="ps-3">
                <h3 className="h5 fw-semibold">No hassle</h3>
                <p className="mb-0">
                  Relax while we handle every detail of property management for
                  you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
