import React from "react";
import ReactPlayer from "react-player";
import learncover from "../assets/img/howcarreworksfrontend.jpg";
import video from "./../assets/welcome-to-carre_V2.mp4";

const LearnVideo = () => {
  return (
    <section className="position-relative py-5">
      <span
        className="position-absolute top-0 start-0 w-100 h-100 d-dark-mode-none"
        style={{
          background: "linear-gradient(360deg, #fff 5.39%, #f6f9fc 78.66%)",
        }}
      />
      <span
        className="position-absolute top-0 start-0 w-100 h-100 d-none d-dark-mode-block"
        style={{
          background:
            "linear-gradient(360deg, #121519 5.39%, rgba(255, 255, 255, .03) 78.66%)",
        }}
      />
      <div className="container position-relative z-2 py-2 py-sm-3 py-sm-4 py-md-5">
        <div className="row align-items-center py-lg-2 py-xl-3 my-xl-1 my-xxl-3">
          <div className="col-md-7">
            <h2 className="h1 pb-2 pb-sm-3 mb-md-0">
              Learn <span className="text-carre-blue">how Carré works</span> in
              a nutshell. Watch a short introductory video
            </h2>
          </div>

          <div className="col-md-5 " style={{ height: "700px" }}>
            <ReactPlayer
              url={video} // Local video file
              light={learncover} // Thumbnail image
              playing={true} // Do not autoplay
              controls={true} // Show video controls
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnVideo;
